// src/requestPermission.ts
import { subscribeUser } from '../pushSubscribe';

export function requestNotificationPermission(): void {
    console.log('Requesting notification permission', Notification?.permission);
    if (Notification?.permission === 'default') {
        Notification?.requestPermission().then((permission: NotificationPermission) => {
            if (permission === 'granted') {
                console.log('Permission granted');
                return subscribeUser();
            } else {
                console.warn('Permission denied');
            }
        }).catch((error) => {
            console.error('Error requesting notification permission', error);
        });
    }
}
