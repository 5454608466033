import { Card } from "../../../components/Card";

interface PlayerVoteCardProps {
    name: string;
    position: string;
    id: string;
    onVote: (playerId: string) => void;
}

export const PlayerVoteCard: React.FC<PlayerVoteCardProps> = ({ name, position, id, onVote }) => {
    return (
        <Card className="cursor-pointer text-center" onClick={() => onVote(id)}>
            <h3 className="font-semibold">{name}</h3>
            <p className="text-gray-600">{position}</p>
        </Card>
    );
}