import { FC, useState } from "react";
import { TimerCard } from "../TimerCard";
import { useAppSelector } from "../../../store";
import { selectMatchPlayerVote } from "../../../store/selectors";
import { VoteStatus } from "../../../store/slices/matchPlayerVote/types";
import { Button } from "../../../components/Button";
import { MatchBanner } from "../MatchBanner";
import { Popup } from "../../../components/Popup";
import { CloseIcon } from "../../../components/Icons";

interface MainScreenProps {
    voteStart: () => void;
    timerText: string;
}

export const MainScreen: FC<MainScreenProps> = ({
    voteStart,
    timerText
}) => {
    const matchPlayerVote = useAppSelector(selectMatchPlayerVote);
    const [showDescription, setShowDescription] = useState(false);
    return (
        <>
            <div>
                <h1 className="text-xl text-center font-bold">Лучший игрок матча</h1>
                {matchPlayerVote.matchName && <h1 className="text-xl text-center font-bold">«{matchPlayerVote.matchName}»</h1>}
            </div>
            <MatchBanner />
            <Button title="Голосовать" onClick={voteStart} disabled={matchPlayerVote.status !== VoteStatus.STARTED} />
            {(matchPlayerVote.status === VoteStatus.CLOSED || (matchPlayerVote.status === VoteStatus.CREATED/* && !matchPlayerVote.startDate*/)) &&
                <div className="text-center font-semibold">
                    <div className="text-center">
                        Голосование начнется не позже 75-й минуты матча.
                    </div>
                    <div className="text-center">
                        Выбирай игрока и получишь шанс лично вручить ему приз. <span className="text-blue-500" onClick={() => setShowDescription(true)}>Подробнее</span>
                    </div>
                </div>}
            {matchPlayerVote.status === VoteStatus.CREATED && <div>
                <TimerCard
                    timeText={timerText}
                    title="До начала голосования"
                />
            </div>}
            {showDescription && (
                <Popup
                    onClose={() => setShowDescription(false)}
                    title="Чтобы принять участие в голосовании тебе нужно"
                >
                    <div className="text-lg text-white flex flex-col gap-2 p-2">
                        <div>
                            1. ⁠Оформить билет
                        </div>
                        <div>
                            2. Сохранить номер билета
                        </div>
                        <div>
                            3. ⁠Прийти на матч
                        </div>
                        <div>
                            4. ⁠Открыть приложение "Родные" после 75-й мин. матча
                        </div>
                        <div>
                            5. ⁠Проголосовать за лучшего игрока (потребуется номер билета)
                        </div>
                    </div>
                    <div className="text-lg text-white p-2">
                        Из проголосовавших за лучшего игрока случайным образом будет выбран болельщик, который получит возможность вручить приз игроку лично.
                    </div>
                    <div className="flex flex-col gap-2 items-center">
                        <Button href="https://fcrodina.com/ticket-id9/" title="БИЛЕТЫ" type="secondary" className="transition-all border-2 border-solid border-white text-center bg-white text-sky-950 w-full p-3 text-2xl font-bold hover:bg-sky-950 hover:text-white" />
                        <Button href="https://fcrodina.com/matchday/" title="Матчдей" type="link" className="transition-all border-2 border-solid border-transparent text-center text-white w-full p-3 text-2xl font-bold hover:no-underline hover:border-white" />
                    </div>
                </Popup >
            )}
        </>
    );
}
