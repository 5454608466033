export const TestPage = () => {
    return (
        <>
            {/* Banner or Hero Section */}
            <section className="bg-blue-300 text-white p-6 sm:p-8 lg:p-10 rounded-lg">
                <h2 className="text-lg font-semibold">Welcome to the Mobile App</h2>
                <p className="mt-2">This is a brief introduction about the app.</p>
                <button className="mt-4 px-4 py-2 bg-blue-700 rounded-lg text-white">Get Started</button>
            </section>

            {/* Feature Cards */}
            <section className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                <div className="bg-white p-4 rounded-lg shadow">
                    <h3 className="font-semibold">Feature 1</h3>
                    <p className="text-gray-600">Description of feature 1.</p>
                </div>
                <div className="bg-white p-4 rounded-lg shadow">
                    <h3 className="font-semibold">Feature 2</h3>
                    <p className="text-gray-600">Description of feature 2.</p>
                </div>
                <div className="bg-white p-4 rounded-lg shadow">
                    <h3 className="font-semibold">Feature 3</h3>
                    <p className="text-gray-600">Description of feature 3.</p>
                </div>
                <div className="bg-white p-4 rounded-lg shadow">
                    <h3 className="font-semibold">Feature 1</h3>
                    <p className="text-gray-600">Description of feature 1.</p>
                </div>
                <div className="bg-white p-4 rounded-lg shadow">
                    <h3 className="font-semibold">Feature 2</h3>
                    <p className="text-gray-600">Description of feature 2.</p>
                </div>
                <div className="bg-white p-4 rounded-lg shadow">
                    <h3 className="font-semibold">Feature 3</h3>
                    <p className="text-gray-600">Description of feature 3.</p>
                </div>
            </section>
        </>
    );
}