export const checkStandaloneMode = () => {
    if (window.matchMedia('(display-mode: standalone)').matches || (window?.navigator as any)?.standalone === true) {
        // User is currently navigating on the PWA so yes it's installed
        localStorage.setItem('pwaInstalled', '1');
    } else {
        //User is navigating in browser
        window.addEventListener('beforeinstallprompt', () => {
            localStorage.setItem('pwaInstalled', '0');
            //User can get an installation prompt meaning the app is not installed
        });
        window.addEventListener('onappinstalled', () => {
            localStorage.setItem('pwaInstalled', '1');
        });
    }
}

export const isPWAInstalled = () => {
    return localStorage.getItem('pwaInstalled') === '1';
}

export const isPWAInstallable = () => {
    return localStorage.getItem('pwaInstalled') === '0';
}

export const isIOS = () => {
    return /iPad|iPhone|iPod/.test(navigator.userAgent);
}
