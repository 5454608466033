import React, { FC } from "react";

export interface BottomMenuItemProps {
    title: string;
    icon: React.ReactNode;
    href: string;
    target?: string;
}

export const BottomMenuItem: FC<BottomMenuItemProps> = ({ title, href, icon, target = '_self' }) => {
    return (
        <a href={href} target={target} className="flex flex-col items-center">
            <span>{icon}</span>
            <span className="text-xs">{title}</span>
        </a>
    );
}