import { useEffect, useState } from "react";
import logo from '../logo.svg';
import { isIOS, isPWAInstalled } from "../utils";
import { CheckIcon, PlusSquareIcon, ShareIOSIcon } from "./Icons";
import { Popup } from "./Popup";
import { Button } from "./Button";

export const InstallBanner = () => {
    const [deferredPrompt, setDeferredPrompt] = useState<Event | null>(null);
    const [isInstallable, setIsInstallable] = useState(false);
    const [showGuide, setShowGuide] = useState(false);

    useEffect(() => {
        const handler = (event: Event) => {
            event.preventDefault();
            setDeferredPrompt(event);  // Save the event
            setIsInstallable(true);    // Show the install button
        };

        if (isIOS() && !isPWAInstalled()) {
            setIsInstallable(true);
        }

        window.addEventListener('beforeinstallprompt', handler);

        return () => {
            window.removeEventListener('beforeinstallprompt', handler);
        };
    }, []);

    const handleInstallClick = () => {
        if (isIOS()) {
            setShowGuide(true);
            return;
        }
        if (deferredPrompt) {
            (deferredPrompt as any).prompt();  // Show the install prompt
            (deferredPrompt as any).userChoice.then((choiceResult: { outcome: string }) => {
                if (choiceResult.outcome === 'accepted') {
                    console.log('User accepted the install prompt');
                } else {
                    console.log('User dismissed the install prompt');
                }
                setDeferredPrompt(null);
                setIsInstallable(false);
            });
        }
    };

    if (!isInstallable) {
        return null;
    }

    if (showGuide) {
        return (
            <Popup>
                <div className="text-white mx-auto">
                    <div className="text-xl font-semibold">
                        Выполни следующие шаги, чтобы взаимодействовать с приложением стало удобнее:
                    </div>
                </div>
                <div className='text-white font-bold flex flex-col gap-4 mx-auto'>
                    <p className="flex flex-row flex-wrap items-center gap-1">
                        <span className="mr-1">1.</span><span>Открой эту страницу в Safari</span>
                    </p>
                    <p className="flex flex-row flex-wrap items-center gap-1">
                        <span className="mr-1">2.</span><span>Нажми на значок</span><ShareIOSIcon /><span>внизу экрана</span>
                    </p>
                    <p className="flex flex-row flex-wrap items-center gap-1">
                        <span className="mr-1">3.</span>Выбери "На экран Домой" <PlusSquareIcon />
                    </p>
                    <p className="text-center w-full">
                        ...
                    </p>
                    <p className="flex flex-row flex-wrap items-center gap-1">
                        <span className="mr-1">4.</span>Топи за «Родину»!
                    </p>
                </div>
                <Button
                    onClick={() => setShowGuide(false)}
                    title="Добавлю позже"
                    type="link"
                    className="transition-all border-2 border-solid border-transparent text-center text-white w-full p-3 text-xl font-bold hover:no-underline hover:border-white"
                />
            </Popup>
        );
    }

    return (
        <div className="w-full p-2 px-3 bg-sky-900 flex flex-row gap-2 justify-center items-center" onClick={handleInstallClick}>
            <div className="w-16 rounded-md bg-white p-1">
                <img src={logo} className="w-full" alt="logo" />
            </div>
            <div className='w-full flex flex-row items-center'>
                <div className='text-white p-2'>
                    Добавь на домашний экран
                </div>
                <button className="min-w-28 h-8 bg-white opacity-95 text-center rounded-md">
                    Установить
                </button>
            </div>
        </div>
    )
};