import { Card } from "../../components/Card";

interface TimerCardProps {
    timeText: string;
    title: string;
}

export const TimerCard: React.FC<TimerCardProps> = ({ title, timeText }) => {
    if (!timeText) {
        return null;
    }

    return (
        <Card>
            {title && <h1 className="text-xl text-center">{title}</h1>}
            <h1 className="text-2xl text-center">{timeText}</h1>
        </Card>
    );
}