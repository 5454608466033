interface CardProps {
    children: React.ReactNode;
    className?: string;
    onClick?: () => void;
}

export const Card: React.FC<CardProps> = ({ children, className, onClick }) => {
    let classNames = "bg-white shadow-md rounded-md p-2 py-3";

    if (className) {
        classNames += ` ${className}`;
    }

    return (
        <div className={classNames} onClick={onClick}>
            {children}
        </div>
    );
}