import { all, call } from 'redux-saga/effects';
import { matchVoteSaga } from '../slices/matchPlayerVote/sagas';  // Example saga

// The root saga combines all other sagas
export function* rootSaga() {
  yield all([
    call(matchVoteSaga), // Add other sagas here
  ]);
}

export default rootSaga;
