const BACKEND_URL = process.env.REACT_APP_BACKEND_URL || process.env.NEXT_PUBLIC_BACKEND_URL;

export async function subscribeUser(): Promise<void> {
    if (!('serviceWorker' in navigator) || !('PushManager' in window)) {
        console.warn('Push messaging is not supported');
        return;
    }
    console.info('Push messaging available');
    try {
        const registration = await navigator.serviceWorker.ready;
        const key = process.env.REACT_APP_VAPID_PUBLIC_KEY || process.env.NEXT_PUBLIC_VAPID_PUBLIC_KEY;
        if (!key) {
            return;
        }

        const subscription = await registration.pushManager.subscribe({
            userVisibleOnly: true,
            applicationServerKey: urlBase64ToUint8Array(key), // Convert key if needed
        });

        // Define a subscription payload interface
        interface PushSubscriptionPayload {
            endpoint: string;
            keys: {
                p256dh: string;
                auth: string;
            };
        }

        // Cast the subscription object to our interface
        const payload: PushSubscriptionPayload = {
            endpoint: subscription.endpoint,
            keys: {
                p256dh: subscription?.toJSON()?.keys?.p256dh || '',
                auth: subscription?.toJSON()?.keys?.auth || '',
            },
        };

        // Send the subscription to the server
        await fetch(`${BACKEND_URL}/push/subscribe`, {
            method: 'POST',
            body: JSON.stringify(payload),
            headers: {
                'Content-Type': 'application/json',
            },
        });

        registration.showNotification("Спасибо, Родной!", {
            body: "Теперь ты будешь в курсе событий",
            icon: "./logo512.png",
            tag: "onboarding",
          });

        console.log('User subscribed');
    } catch (error) {
        console.error('Failed to subscribe the user:', error);
    }
}

// Utility function to convert the VAPID key
function urlBase64ToUint8Array(base64String: string): Uint8Array {
    const padding = '='.repeat((4 - (base64String.length % 4)) % 4);
    const base64 = (base64String + padding).replace(/-/g, '+').replace(/_/g, '/');
    const rawData = window.atob(base64);
    const outputArray = new Uint8Array(rawData.length);

    for (let i = 0; i < rawData.length; ++i) {
        outputArray[i] = rawData.charCodeAt(i);
    }
    return outputArray;
}
