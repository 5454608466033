import { useState, useEffect } from 'react';
import logo from './logo.svg';
import './App.css';
import MobileLayout from './layout/MobileLayout';
import { Navigate, Route, Routes } from 'react-router-dom';
import { TestPage } from './pages/TestPage';
import { MatchMVPVotePage } from './pages/mvp-vote';
import { SplashScreen } from './components/SplashScreen';
import { RequestNotificationBanner } from './components/RequestNotifications';
import { InstallBanner } from './components/InstallBanner';

const App = () => {
    const [loading, setLoading] = useState(true);
    const [showSplashScreen, setShowSplashScreen] = useState(true);
    const [hasToken, setHasToken] = useState(false);

    useEffect(() => {
        /*const searchParams = new URLSearchParams(window.location.search);

        if (searchParams.has('access_token')) {
            const accessToken = searchParams.get('access_token');
            localStorage.setItem('access_token', accessToken as string);
            window.location.search = '';
        }*/

        //if (localStorage.getItem('access_token') === 'my-rodina-ui') {
            setHasToken(true);
            setTimeout(() => {
                setShowSplashScreen(false);
                setTimeout(() => {
                    setLoading(false);
                }, 500);
            }, 3000);
        //}
    }, []);

    return (
        <>
            {hasToken && <>
                <InstallBanner />
                <RequestNotificationBanner />
                <MobileLayout>
                    <Routes>
                        <Route path="/vote" element={<MatchMVPVotePage />} >
                            <Route path=":page" element={<MatchMVPVotePage />} />
                        </Route>
                        {/*<Route path="/" element={<TestPage />} />*/}
                        <Route path="/test" element={<TestPage />} />
                        <Route path="/test2" element={<div className="App">
                            <header className="App-header">
                                <img src={logo} className="App-logo" alt="logo" />
                            </header>
                        </div>} />
                        <Route path="*" element={<Navigate to="/vote" replace />} />
                    </Routes>
                </MobileLayout>
            </>}
            {loading && <SplashScreen fade={!showSplashScreen} />}
        </>
    );
};

export default App;
