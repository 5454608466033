import { FC, useCallback, useEffect, useRef, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../store";
import { selectTicket } from "../../../store/slices/matchPlayerVote/reducer";
import { selectMatchPlayerVote } from "../../../store/selectors";
import { TicketCheckResult } from "../../../store/slices/matchPlayerVote/types";
import helperImage from "../../../assets/ticketNumber.png";

interface TicketFormProps {
    onSuccess?: () => void;
}

const questionIcon = (
    <svg className="w-6 h-6 text-blue-400 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
        <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9.529 9.988a2.502 2.502 0 1 1 5 .191A2.441 2.441 0 0 1 12 12.582V14m-.01 3.008H12M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
    </svg>
);

export const TicketForm: FC<TicketFormProps> = ({
    onSuccess,
}) => {
    const [ticketChecking, setTicketChecking] = useState<boolean>(false);
    const ticketRef = useRef<HTMLInputElement>(null);
    const phoneRef = useRef<HTMLInputElement>(null);
    const dispatch = useAppDispatch();
    const [helperImageShown, setHelperImageShown] = useState(false);

    const matchPlayerVote = useAppSelector(selectMatchPlayerVote);

    const onSelectedTicketHandler = useCallback((ticket: string, phone: string) => {
        if (!ticket || !phone) {
            return;
        }

        localStorage.setItem("phoneNumber", phone);
        localStorage.setItem("selectedTicket", ticket);

        setTicketChecking(true);

        dispatch(selectTicket(ticket));
    }, [dispatch]);

    useEffect(() => {
        if (ticketChecking && matchPlayerVote.ticketCheckResult === TicketCheckResult.OK) {
            setTimeout(() => {
                onSuccess?.();
            }, 500);
        }

        setTicketChecking(!!matchPlayerVote.ticketChecking);
    }, [matchPlayerVote, onSuccess, ticketChecking]);

    useEffect(() => {
        const phoneNumber = localStorage.getItem("phoneNumber");
        if (phoneNumber) {
            phoneRef.current!.value = phoneNumber;
        }
    }, []);

    const ticketCheckError = matchPlayerVote.ticketCheckResult === TicketCheckResult.ERROR;
    const ticketSelected =
        !!matchPlayerVote.selectedTicket &&
        matchPlayerVote.ticketCheckResult === TicketCheckResult.OK &&
        !!phoneRef.current?.value;

    return (
        <div>
            {helperImageShown && <div className="fixed inset-0 bg-white bg-opacity-100 z-50 flex flex-col items-center justify-between" onClick={() => setHelperImageShown(false)}>
                <img
                    src={helperImage}
                    alt="Билет"
                    className="max-w-full max-h-full border border-gray-300"
                />
                <button className="bg-sky-950 text-lg text-white p-4 rounded-sm transition-colors duration-300 w-full" onClick={() => setHelperImageShown(false)}>
                    Уже нашёл
                </button>
            </div>}
            <div /*className="fixed left-0 right-0 top-0 bottom-0 bg-white p-12 flex flex-col"*/>
                <p className="text-justify text-sm mb-4 italic">
                    Мы получили твой голос, теперь нам нужно убедиться, что ты на матче, т.к. по <a href="" className="text-blue-500">условиям голосования</a> лучшего игрока выбирают зрители на трибунах.
                </p>
                <p className="text-justify text-sm mb-4 italic">
                    Для этого введи номер билета, по которому ты прошел на стадион и оставь свой номер телефона, чтобы связаться с тобой, если случайный выбор определит тебя в качестве победителя.
                </p>
            </div>

            <div className="flex items-center justify-center gap-1 mb-4">
                <label className="block text-center">Номер билета</label>
                <div onClick={() => setHelperImageShown(true)}>{questionIcon}</div>
            </div>
            <input
                type="text"
                placeholder="Введите 8-значный номер"
                className="w-full p-2 border border-gray-300 rounded-lg mb-1 mt-1 text-center"
                disabled={ticketSelected}
                ref={ticketRef}
                required
                maxLength={8}
                minLength={8}
                defaultValue={matchPlayerVote.selectedTicket || ""}
            />
            {!ticketCheckError && <label className="block text-sm mb-4">
                Введите 8-значный номер билета
            </label>}
            {ticketCheckError && <label className="block text-red-500 mb-4 text-sm">
                Билет не отсканирован, проверьте, правильно ли введён номер
            </label>}
            <label className="block text-center">Номер телефона</label>
            <input
                type="tel"
                placeholder="+70000000000"
                className="w-full p-2 border border-gray-300 rounded-lg mb-1 mt-1 text-center"
                disabled={ticketSelected}
                required
                maxLength={12}
                ref={phoneRef}
                defaultValue={matchPlayerVote.selectedTicket || ""}
            />
            <label className="block text-sm mb-4">
                По этому номеру мы свяжемся, если вы будете выбраны для вручения награды лучшему игроку
            </label>
            {!ticketSelected && <button
                className="w-full bg-blue-500 text-white p-2 rounded-sm transition-colors duration-300"
                onClick={(event) => onSelectedTicketHandler(ticketRef.current?.value || "", phoneRef.current?.value || "")}
                disabled={ticketChecking || ticketSelected}
            >
                {ticketChecking && <svg aria-hidden="true" role="status" className="inline w-4 h-4 me-3 text-gray-200 animate-spin dark:text-gray-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
                    <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="#1C64F2" />
                </svg>}
                Отправить
            </button>}
        </div>
    );
}
