import { FC, useEffect, useState } from "react";
import { CloseIcon } from "./Icons";
interface PopupProps {
    children: React.ReactNode;
    onClose?: () => void;
    title?: string;
}

export const Popup: FC<PopupProps> = ({ children, onClose, title }) => {
    const [show, setShow] = useState(false);

    useEffect(() => {
        setShow(true);

        return () => {
            setShow(false);
        };
    }, []);

    return (
        <div className="transition-all fixed top-0 bottom-0 left-0 right-0 z-50 w-full p-2 bg-sky-950 flex flex-col gap-2 justify-center items-center sm:bg-slate-950/50 sm:p-8">
            <div className="relative w-full sm:max-w-96 bg-sky-950 flex flex-col justify-between h-full p-2 sm:rounded sm:p-4 overflow-auto">
                {onClose && <div className="absolute right-2 top-2 cursor-pointer" onClick={onClose}>
                    <CloseIcon/>
                </div>}
                {title && <h2 className="text-white text-xl font-semibold py-4 px-2 text-center">{title}</h2>}
                {children}
            </div>
        </div>
    )
};