import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { MatchPlayerVoteDto, VoteStatus, TicketCheckResult, FixtureShortInfoDto } from './types';
//import { TeamConfig, TournamentConfig } from '../../../types';

// Define a type for the slice state
export interface MatchPlayerVoteState extends MatchPlayerVoteDto {
    loading?: boolean;
    ticketChecking?: boolean;
    ticketCheckResult: TicketCheckResult;
    fixtureData?: FixtureShortInfoDto;
}

// Define the initial state using that type
const initialState: MatchPlayerVoteState = {
    uuid: '',
    status: VoteStatus.CLOSED,
    eventId: 0,
    matchName: '',
    candidates: [],
    selectedPlayer: '',
    selectedTicket: '',
    loading: false,
    ticketChecking: false,
    ticketCheckResult: TicketCheckResult.UNKNOWN,
    bannerUrl: '',
    matchId: 0,
};

const matchPlayerVote = createSlice({
    name: 'matchPlayerVote',
    initialState,
    reducers: {
        loadMatchPlayerVote: (state, action: PayloadAction<{
            uuid?: string;
        }>) => {
            state.loading = true;
        },
        loadMatchPlayerVoteSuccess: (state, action: PayloadAction<MatchPlayerVoteDto>) => {
            state.uuid = action.payload.uuid;
            state.matchName = action.payload.matchName;
            state.candidates = action.payload.candidates;
            state.startDate = action.payload.startDate;
            state.endDate = action.payload.endDate;
            state.eventId = action.payload.eventId;
            state.bannerUrl = action.payload.bannerUrl || "https://fcrodina.com/news/cbe3021c2f4ff2d8a9fe0f99c2ef4073.jpg";
            state.status = action.payload.status;
            state.result = action.payload.result;
            state.matchId = action.payload.matchId;
            state.loading = false;
        },
        loadMatchPlayerVoteFailure: (state, action: PayloadAction<{}>) => {
            state.loading = false;
        },
        selectTicket: (state, action: PayloadAction<string>) => {
            state.ticketChecking = true;
            state.selectedTicket = action.payload;
        },
        selectTicketSuccess: (state, action: PayloadAction<TicketCheckResult>) => {
            state.ticketCheckResult = action.payload;
            state.ticketChecking = false;
        },
        selectTicketFailure: (state, action: PayloadAction<{}>) => {
            state.ticketChecking = false;
            state.ticketCheckResult = TicketCheckResult.ERROR;
        },
        selectPlayer: (state, action: PayloadAction<string>) => {
            state.selectedPlayer = action.payload;
        },
        resetPlayerSelection: (state) => {
            state.selectedPlayer = '';
        },
        loadFixtureData: (state, action: PayloadAction<{ matchId: number }>) => {
        },
        loadFixtureDataFailure: (state, action: PayloadAction<{}>) => {
            state.fixtureData = undefined;
        },
        loadFixtureDataSuccess: (state, action: PayloadAction<FixtureShortInfoDto>) => {
            state.fixtureData = action.payload;
        }
    }
})

export const {
    loadMatchPlayerVote,
    loadMatchPlayerVoteSuccess,
    loadMatchPlayerVoteFailure,
    selectTicket,
    selectTicketSuccess,
    selectTicketFailure,
    selectPlayer,
    resetPlayerSelection,
    loadFixtureData,
    loadFixtureDataFailure,
    loadFixtureDataSuccess,
} = matchPlayerVote.actions;

export default matchPlayerVote.reducer;