import { useNavigate, useParams } from "react-router-dom";
import { Card } from "../../components/Card";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../store";
import { loadMatchPlayerVote, selectTicket } from "../../store/slices/matchPlayerVote/reducer";
import { selectMatchPlayerVote } from "../../store/selectors";
import { VoteStatus } from "../../store/slices/matchPlayerVote/types";
import { TimerCard } from "./TimerCard";
import { VotePage } from "./types";
import { PlayerSelect, VoteSent, Results } from "./screens";
import { timeRemaining } from "./utils";
import { MainScreen } from "./screens/Main";

interface MatchMVPVotePageProps { }

let loadContestInterval: any = 0;
let timerInterval: any = 0;

const LOAD_CONTEST_INTERVAL = 60 * 1000;

export const MatchMVPVotePage: React.FC<MatchMVPVotePageProps> = () => {
    const [timerText, setTimerText] = useState('');
    const [timerTarget, setTimerTarget] = useState(0);
    const { page } = useParams<{ page?: VotePage }>();
    const navigate = useNavigate();
    const voteStart = () => {
        navigate("/vote/select");
    }
    const dispatch = useAppDispatch();

    const matchPlayerVote = useAppSelector(selectMatchPlayerVote);

    useEffect(() => {
        dispatch(loadMatchPlayerVote({}));

        return () => {
            clearInterval(loadContestInterval);
            clearInterval(timerInterval);
        }
    }, [dispatch]);

    useEffect(() => {
        if (loadContestInterval) {
            clearInterval(loadContestInterval);
        }

        if (!matchPlayerVote.uuid) {
            return;
        }

        dispatch(loadMatchPlayerVote({
            uuid: matchPlayerVote.uuid,
        }));

        loadContestInterval = setInterval(() => {
            dispatch(loadMatchPlayerVote({
                uuid: matchPlayerVote.uuid,
            }));
        }, LOAD_CONTEST_INTERVAL);

    }, [dispatch, matchPlayerVote.uuid]);

    useEffect(() => {
        const ticket = localStorage.getItem("selectedTicket");
        if (ticket) {
            dispatch(selectTicket(ticket));
        }
    }, [dispatch]);

    useEffect(() => {
        clearInterval(timerInterval);
        if (timerTarget) {
            timerInterval = setInterval(() => {
                onTimerUpdate(timerTarget);
            }, 1000);
        }
        onTimerUpdate(timerTarget);
    }, [timerTarget]);


    const onTimerUpdate = (targetTime: number = 0) => {
        setTimerText(timeRemaining(targetTime));
    }

    useEffect(() => {
        if (matchPlayerVote.status === VoteStatus.CREATED) {
            setTimerTarget(matchPlayerVote.startDate || 0);
        } else if (matchPlayerVote.status === VoteStatus.STARTED) {
            setTimerTarget(matchPlayerVote.endDate || 0);
        }

        /*
        if (matchPlayerVote.status === VoteStatus.CREATED) {
            if (matchPlayerVote.startDate) {
                clearInterval(timerInterval);
                let nextUpdate = 0;
                if (matchPlayerVote.startDate > Date.now()) {
                    nextUpdate = matchPlayerVote.startDate - Date.now();
                } else {
                    nextUpdate = 60 * 1000;
                }

                setTimeout(() => {
                    dispatch(loadMatchPlayerVote({}));
                }, nextUpdate);

                onTimerUpdate(matchPlayerVote.startDate);
                const interval = setInterval(() => {
                    onTimerUpdate(matchPlayerVote.startDate);
                }, 1000);
                setTimerInterval(interval);
            }
        } else if (matchPlayerVote.status === VoteStatus.STARTED) {
            if (matchPlayerVote.endDate) {
                clearInterval(timerInterval);
                onTimerUpdate(matchPlayerVote.endDate || 0);
                const interval = setInterval(() => {
                    onTimerUpdate(matchPlayerVote.endDate || 0);
                }, 1000);
                setTimerInterval(interval);
            }
        }
            */

        return () => {
            clearInterval(timerInterval);
        }
    }, [matchPlayerVote.status, matchPlayerVote.startDate, matchPlayerVote.endDate, dispatch]);

    useEffect(() => {
        if (matchPlayerVote.status === VoteStatus.CLOSED || matchPlayerVote.status === VoteStatus.CREATED) {
            if (!!page) {
                navigate("/vote");
            }
        } else if (matchPlayerVote.status === VoteStatus.STARTED) {
            if (page !== "select" && page !== "sent") {
                navigate("/vote/select");
            }
        } else if (matchPlayerVote.status === VoteStatus.WAITING_RESULTS) {
            if (page !== "wait-result") {
                navigate("/vote/wait-result");
            }
        } else if (matchPlayerVote.status === VoteStatus.COMPLETE) {
            if (page !== "result") {
                navigate("/vote/result");
            }
        }
    }, [matchPlayerVote.endDate, matchPlayerVote.status, navigate, page]);

    const voteTimeIsOver = matchPlayerVote.endDate && matchPlayerVote.endDate < Date.now();

    const endVoteTimerCard = <TimerCard
        timeText={timerText}
        title="До конца голосования"
    />;

    return (
        <section className="w-full grid grid-cols-1 gap-4 max-w-sm py-2">
            {!page && (
                <MainScreen voteStart={voteStart} timerText={timerText} />
            )}
            {page === VotePage.PlayerSelect && (
                <PlayerSelect>
                    {!voteTimeIsOver && endVoteTimerCard}
                </PlayerSelect>
            )}
            {page === VotePage.VoteSent && (
                <VoteSent>
                    {!voteTimeIsOver && endVoteTimerCard}
                </VoteSent>
            )}
            {page === VotePage.Result && (
                <Results />
            )}
            {page === VotePage.WaitResult && (
                <Card>
                    <h1 className="text-xl text-center font-bold mb-4">Ожидаем результатов</h1>
                    <p className="text-center">Результаты голосования будут объявлены в ближайшее время</p>
                </Card>
            )}
        </section>
    );
}