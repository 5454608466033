export const timeRemaining = (targetTime: number, zeroTimeText = 'считанные секунды'): string => {
    const now = Date.now();
    let diff = targetTime - now;
    if (diff < 0) {
        return zeroTimeText;
    }

    const days = Math.floor(diff / 86400000);
    diff -= days * 86400000;
    const hours = Math.floor((diff % 86400000) / 3600000);
    diff -= hours * 3600000;
    const minutes = Math.floor((diff % 3600000) / 60000);
    diff -= minutes * 60000;
    const seconds = Math.floor((diff % 60000) / 1000);

    let text = "";

    if (days > 0) {
        text = `${days}д ${hours}:`;
    } else if (hours > 0) {
        text = `${hours}:`;
    }

    if (hours > 0 || days > 0) {
        text += `${minutes > 9 ? minutes : `0${minutes}`}:`;
    } else {
        text = `${minutes}:`;
    }

    text += `${seconds > 9 ? seconds : `0${seconds}`}`;

    return text;
}

export const dataURItoBlob = (dataURI: string): Blob => {
    // convert base64 to raw binary data held in a string
    // doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
    var byteString = atob(dataURI.split(',')[1]);

    // separate out the mime component
    var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

    // write the bytes of the string to an ArrayBuffer
    var ab = new ArrayBuffer(byteString.length);
    var ia = new Uint8Array(ab);
    for (var i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
    }

    return new Blob([ab], { type: mimeString });
}

export const blobToFile = (blob: Blob, fileName: string): File => {
    const file = new File([blob], fileName, {
        type: blob.type,
        lastModified: Date.now(), // Optionally, set the last modified date
    });
    return file;
}

export const dataURItoFile = (dataURI: string, fileName: string): File => {
    const blob = dataURItoBlob(dataURI);
    return blobToFile(blob, fileName);
}