import { FC } from "react";

interface ButtonProps {
    title: string;
    onClick?: () => void;
    href?: string;
    type?: "primary" | "secondary" | "link" | "danger";
    disabled?: boolean;
    className?: string;
}

export const Button: FC<ButtonProps> = ({
    title,
    onClick,
    href,
    type = "primary",
    disabled,
    className = '',
}) => {
    let classNames = "text-white text-md p-3 rounded-sm transition-colors duration-300";

    if (type === "primary") {
        classNames += " bg-sky-950 hover:bg-sky-900";
    } else if (type === "secondary") {
        classNames = "bg-gray-500 hover:bg-gray-600";
    } else if (type === "danger") {
        classNames = "bg-red-500 hover:bg-red-600";
    } else if (type === "link") {
        classNames = "text-blue-500 hover:underline";
    }

    if (disabled) {
        classNames += " opacity-50 cursor-not-allowed pointer-events-none";
    }

    if (className) {
        classNames += ` ${className}`;
    }

    if (href) {
        return (
            <a href={href} className={classNames}>{title}</a>
        );
    }

    return (
        <button className={classNames} onClick={onClick}>{title}</button>
    );
}
