import { useEffect } from "react";
import { Card } from "../../../components/Card";
import { useAppDispatch, useAppSelector } from "../../../store";
import { selectMatchPlayerVote } from "../../../store/selectors";
import { MatchBanner } from "../MatchBanner";
import { loadFixtureData } from "../../../store/slices/matchPlayerVote/reducer";
import { ShareIcon } from "../../../components/Icons";
import html2canvas from "html2canvas";
import shareBg from "../../../assets/shareBg.png";
import { dataURItoBlob, dataURItoFile } from "../utils";

export const Results = () => {
    const ticketLink = "https://fcrodina.com/tickets-id9/";
    const matchPlayerVote = useAppSelector(selectMatchPlayerVote);
    const isMyTicket = matchPlayerVote.result?.ticketId === matchPlayerVote.selectedTicket;
    const dispatch = useAppDispatch();

    useEffect(() => {
        console.log(matchPlayerVote);
        if (!matchPlayerVote.matchId) {
            return;
        }
        dispatch(loadFixtureData({ matchId: matchPlayerVote.matchId }));
    }, [dispatch, matchPlayerVote.matchId]);

    const handleDownloadImage = async () => {
        const element = document.getElementById('print');
        if (!element) {
            return;
        }

        element.style.display = 'block';

        const canvas = await html2canvas(element, {
            //foreignObjectRendering: true,
            backgroundColor: '#ccc',
            //useCORS: true,
            allowTaint: true,
        });

        /*const ctx = canvas.getContext('2d');
        const img = new Image();
        if (!ctx) {
            return;
        }

        if(!matchPlayerVote?.bannerUrl) {
            return;
        }

        img.src = matchPlayerVote?.bannerUrl; // Replace with your image URL

        // When the image is loaded, draw it with 25% opacity
        img.onload = function () {
            // Set global alpha to 0.25 for 25% opacity
            ctx!.globalAlpha = 0.25;

            // Draw the image at specified coordinates (x: 50, y: 50 in this example)
            ctx!.drawImage(img, 300, 300);

            // Reset global alpha back to 1 for full opacity for subsequent drawings
            ctx!.globalAlpha = 1;
        };*/

        const data = canvas.toDataURL('image/jpg');


        if (navigator.share) {
            navigator.share({
                title: '#РоднаяНаграда',
                text: `${matchPlayerVote.result?.winner} получит от меня приз лучшему игроку матча ${matchPlayerVote?.matchName} #РоднаяНаграда #ТопиЗаРодину`,
                files: [dataURItoFile(data, 'winner.jpg')],
            })
                .then(() => console.log('Поделиться успешно'))
                .catch((error) => console.error('Ошибка при попытке поделиться:' + error.message));
        } else {
            const link = document.createElement('a');

            //element.style.display = 'none';

            link.href = data;
            link.download = 'winner.jpg';

            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
    };

    return (
        <>
            <MatchBanner />
            <Card>
                <h1 className="text-lg text-center">Лучшим игроком матча выбран</h1>
                <p className="text-center font-bold text-xl text-sky-900">{matchPlayerVote.result?.winner}</p>
            </Card>
            <Card>
                {!isMyTicket && matchPlayerVote.result?.ticketId && <p className="text-center">Награду вручит зритель с билетом №{matchPlayerVote.result?.ticketId}</p>}
                {isMyTicket &&
                    <>
                        <div className="overflow-hidden max-h-0">
                            <div id="print" className="relative bg-sky-100 text-white aspect-square">
                                <img src={shareBg} width={100} alt="banner" className="w-full" />
                                <div className="absolute top-0 flex flex-col justify-between h-full p-4 text-center">
                                    <p className="text-xl text-center font-bold">
                                        🎉 Поздравляем! 🎉
                                    </p>
                                    <div>
                                        <p>Твой игрок</p>
                                        <p className="text-center font-bold text-xl">{matchPlayerVote.result?.winner}</p>
                                        <p>выбран лучшим игроком матча</p>
                                    </div>
                                    <p className="text-center font-bold text-2xl">{matchPlayerVote?.matchName}</p>
                                    <p className="text-center">А ты получил право вручить ему приз на следующем матче</p>
                                    <p className="text-xl text-center uppercase font-bold italic">
                                        #роднаянаграда
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="relative">
                            <div className="absolute right-2 top-0" onClick={handleDownloadImage}>
                                {<ShareIcon />}
                            </div>
                            <div className="text-center">
                                <h1 className="font-bold">Поздравляем!</h1>
                                <br />
                                Твой игрок стал лучшим, а ты получил право вручить ему приз на следующем матче. Не забудь оформить на него <a className="text-blue-500" href={ticketLink} target="_blank" rel="noreferrer noopener">билет</a>
                                <br />
                                <br />
                                По дальнейшим действиям с тобой по номеру <span className="font-bold">{localStorage.getItem("phoneNumber")}</span> свяжется наш менеджер.
                                <br />
                                <br />
                            </div>
                            <p className="text-xl text-center uppercase font-bold italic text-sky-900">
                                #топизародину
                            </p>
                        </div>
                    </>
                }
            </Card>
        </>
    )
}
