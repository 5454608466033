import { BACKEND_URL } from "./consts";

const TRANSPORT_BASE_URL = `${BACKEND_URL}/fixtures`;

export const getFixtures = async () => {
    const fixtures = await fetch(TRANSPORT_BASE_URL, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
        },
    });

    return fixtures.json();
}

export const getFixture = async (id: number) => {
    const fixture = await fetch(`${TRANSPORT_BASE_URL}/legacy/${id}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
        },
    });

    return fixture.json();
}