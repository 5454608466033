//import configReducer from '../slices/config/reducer';
import matchPlayerVoteReducer from '../slices/matchPlayerVote/reducer';
import { combineReducers } from '@reduxjs/toolkit';

export const rootReducer = combineReducers({
    matchPlayerVote: matchPlayerVoteReducer,
    //calendar: calendarReducer,
    //config: configReducer,
    //team: teamReducer,
    //common: commonReducer,
});